import React, { useRef } from 'react'

// Style imports
import './phosExamples.scss'

// Component imports
import FadeSection from '../../../components/FadeSection'
import PortfolioCard from '../../../components/PortfolioCard'

// Image imports
import ancorpLogo from '../../../assets/ancorpLogo.png'
import etechservicesLogo from '../../../assets/etechservicesLogo.png'
import locklearLogo from '../../../assets/locklearLogo.png'
import wardsLogo from '../../../assets/wardsLogo.png'

// Export component
const PhosExamples = () => {
  // refs
  const phosExamplesHeaderRef = useRef()

  const examples = [
    {
      ref: useRef(),
      linkAddress: 'https://wardsgainesville.com/',
      logo: wardsLogo
    },
    {
      ref: useRef(),
      linkAddress: 'https://ancorp.com/',
      logo: ancorpLogo
    },
    {
      ref: useRef(),
      linkAddress: 'https://www.locklearconsulting.com/',
      logo: locklearLogo
    },
    {
      ref: useRef(),
      linkAddress: 'https://www.e-techservices.com/',
      logo: etechservicesLogo
    }
  ]

  return (
    <div>
      <div ref={phosExamplesHeaderRef}>
        <FadeSection threshold={1} watchElementRef={phosExamplesHeaderRef} reverseThreshold={true}>
          <h4>Some sites I built or contributed to while working at PHOS</h4>
        </FadeSection>
      </div>
      <div className="ervn-phos-examples">
        {
          examples.map((example, index) => {
            return (
              <div ref={example.ref} key={index} className="ervn-example-container">
                <FadeSection threshold={1} watchElementRef={example.ref} reverseThreshold={true}>
                  <PortfolioCard
                    linkAddress={example.linkAddress}
                    logo={example.logo}
                    title={example.title}
                  />
                </FadeSection>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default PhosExamples
