// Style imports
import './App.scss'

// Router imports
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Component imports
import Home from './pages/Home'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
