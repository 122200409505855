import React from 'react'

// Image imports
import phosLogoWhite from '../../../assets/phosLogoWhite.png'

// Export component
const PhosContent = () => {
  return (
    <div id="ErvnPhosContent">
      <div className="ervn-icon-header">
        <a href="https://phoscreative.com/" target="_blank" rel="noreferrer"><img style={{ width: '60px' }} src={phosLogoWhite} alt="phos creative logo" /></a>
        <h3>Previous work for PHOS Creative</h3>
      </div>
      <div>
        <p style={{ margin: 0 }}><a href="https://phoscreative.com/" target="_blank" rel="noreferrer">PHOS Creative</a> is a strategic digital marketing agency for which I built highly customized WordPress sites and themes.</p>
      </div>
    </div>
  )
}

export default PhosContent
