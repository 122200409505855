import React from 'react'

// Export component
const UFContent = () => {
  return (
    <>
      <h3>Developing for the University of Florida</h3>
      <p>I'v been working on an agile team developing <a href="https://one.uf.edu/about" target="_blank" rel="noreferrer">one.uf</a> since 2017. ONE.UF is the primary self-service website for students and faculty at the University of Florida. Over the years, we've continually added features and made performance, accessibility, and UX improvements.</p>
      <p>In addition to our web work, we are developing a new app to provide the ONE.UF services in native environments.</p>
    </>
  )
}

export default UFContent
