import React from 'react'

// Style import
import './copyrightContent.scss'

// Export component
const CopyrightContent = () => {
  const year = new Date().getFullYear()

  return (
    <div className="ervn-copyright">
      <p>&copy;{year} Eric Van Ness</p>
    </div>
  )
}

export default CopyrightContent
