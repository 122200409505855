import React from 'react'

// Image imports
import hsncfLogo from '../../../assets/hsncfLogo.png'

// Export component
const HumaneSocietyContent = () => {
  return (
    <div id="ErvnHumaneSocietyContent">
      <div className="ervn-icon-header">
        <a href="https://www.humanesocietyncfl.org/" target="_blank" rel="noreferrer"><img style={{ width: '60px' }} src={hsncfLogo} alt="phos creative logo" /></a>
        <h3>Humane Society of North Central Florida</h3>
      </div>
      <div>
        <p style={{ margin: 0 }}>I held several positions during my time at the <a href="https://www.humanesocietyncfl.org/" target="_blank" rel="noreferrer">Humane Society of North Central Florida</a> (formerly Alachua County Humane Society), including Executive Director.</p>
        <p>While I was there, we were the lead agency for the <a href="https://www.maddiesfund.org/index.htm" target="_blank" rel="noreferrer">Maddie's Fund</a> project that helped eliminate euthanasia of healthy and treatable companion animals in Alachua County.</p>
      </div>
    </div>
  )
}

export default HumaneSocietyContent
