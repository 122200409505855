import React, { useRef } from 'react'

// Style imports
import './Home.scss'

// Component imports
import BookGalleryContent from './content/BookGalleryContent'
import ClosingContent from './content/ClosingContent'
import CopyrightContent from './content/CopyrightContent'
import FadeSection from '../../components/FadeSection'
import HumaneSocietyContent from './content/HumaneSocietyContent'
import IntroContent from './content/IntroContent'
import PhosContent from './content/PhosContent'
import PhosExamples from './content/PhosExamples'
import SecretSquirrelContent from './content/SecretSquirrelContent'
import SectionDivider from '../../components/SectionDivider'
import UFContent from './content/UFContent'

// Export component
const Home = () => {
  const bookGalleryRef = useRef()
  const closingRef = useRef()
  const experienceHeaderRef = useRef()
  const humaneSocietyRef = useRef()
  const introRef = useRef()
  const phosRef = useRef()

  return (
    <div id="ErvnHome">
      <div className="ervn-header">
        <h1>Eric Van Ness</h1>
        <h2>Bringing visions to life.</h2>
      </div>
      <div className="ervn-content">
        {/* Coding experience */}
        <div className="ervn-content-container">
          {/* intro */}
          <div className="ervn-intro" ref={introRef}>
            <IntroContent />
          </div>
          {/* UF */}
          <div className="ervn-career-small ervn-limit-content-width">
            <FadeSection threshold={1} watchElementRef={introRef} reverseThreshold={false} fadeInOnLoad={true} fadeInOnLoadDelay={1000} >
              <UFContent />
            </FadeSection>
          </div>
          {/* Secret Squirrel */}
          <FadeSection threshold={1} watchElementRef={introRef} reverseThreshold={false} fadeInOnLoad={true} fadeInOnLoadDelay={1100} >
            <SectionDivider />
          </FadeSection>
          <div className="ervn-career ervn-limit-content-width">
            <FadeSection threshold={1} watchElementRef={introRef} reverseThreshold={false} fadeInOnLoad={true} fadeInOnLoadDelay={1150}>
              <SecretSquirrelContent />
            </FadeSection>
          </div>
          {/* Phos */}
          <FadeSection threshold={1} watchElementRef={phosRef} reverseThreshold={true}>
            <SectionDivider />
          </FadeSection>
          <div className="ervn-career ervn-limit-content-width" ref={phosRef}>
            <FadeSection threshold={1} watchElementRef={phosRef} reverseThreshold={true}>
              <PhosContent />
            </FadeSection>
          </div>
          <div className="ervn-limit-content-width">
            <PhosExamples />
          </div>
        </div>
        {/* Past experience */}
        <div className="ervn-content-container">
          <div ref={experienceHeaderRef}>
            <FadeSection threshold={1} watchElementRef={experienceHeaderRef} reverseThreshold={true}>
              <SectionDivider />
              <h2 style={{ marginTop: '60px'}}>Some other things I've done</h2>
            </FadeSection>
          </div>
          {/* Humane Society */}
          <div className="ervn-career ervn-limit-content-width"  ref={humaneSocietyRef}>
            <FadeSection threshold={1} watchElementRef={humaneSocietyRef} reverseThreshold={true}>
              <HumaneSocietyContent />
            </FadeSection>
          </div>
          {/* Book Gallery West */}
          <div className="ervn-limit-content-width"  ref={bookGalleryRef}>
            <FadeSection threshold={1} watchElementRef={bookGalleryRef} reverseThreshold={true}>
              <SectionDivider />
              <BookGalleryContent />
            </FadeSection>
          </div>
          {/* Closing */}<div className="ervn-limit-content-width"  ref={closingRef}>
            <FadeSection threshold={1} watchElementRef={closingRef} reverseThreshold={true}>
              <SectionDivider />
              <ClosingContent />
            </FadeSection>
          </div>
          {/* Copyright */}
          <CopyrightContent />
        </div>
      </div>
    </div>
  )
}

export default Home
