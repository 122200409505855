import React from 'react'
import PropTypes from 'prop-types'

// Style imports
import './portfolioCard.scss'

// Export component
const PortfolioCard = ({ content, linkAddress, logo, title }) => {
  return (
    <div className="ervn-portfolio-card">
      <a href={linkAddress} target="_blank" rel="noreferrer">
        <div>
          <img src={logo} alt={title}/>
        </div>
        { title && <h5>{title}</h5> }
        { content && <p>{content}</p> }
        <div className="ervn-portfolio-card-background"></div>
      </a>
    </div>
  )
}

PortfolioCard.propTypes = {
  content: PropTypes.string,
  linkAddress: PropTypes.string,
  logo: PropTypes.any,
  title: PropTypes.string
}

export default PortfolioCard
