import React from 'react'

// Export component
const ClosingContent = () => {
  return (
    <div style={{ marginTop: '60px' }}>
      <h3>Be well. Have fun!!!</h3>
      <p>Thanks for stopping by.</p>
    </div>
  )
}

export default ClosingContent
