import { useCallback, useMemo } from 'react'

export const useHelperFunctions = () => {
  const getScrollLoadObserver = useCallback(
    ({
      watchElementRef,
      targetElementRef,
      rootRef,
      rootMargin = '0px',
      threshold = 0,
      reverseThreshold
    }) => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (targetElementRef.current) {
            const getClassName = (isIntersecting, reverseThreshold) => {
              if (isIntersecting) {
                return reverseThreshold ? 'ervn-scroll-load ervn-show' : 'ervn-scroll-load'
              }

              return reverseThreshold ? 'ervn-scroll-load' : 'ervn-scroll-load ervn-show'
            }

            targetElementRef.current.className = getClassName(entries[0].isIntersecting, reverseThreshold)
          }
        },
        {
          root: rootRef,
          rootMargin,
          threshold
        }
      )

      return observer
    },
    []
  )

  return useMemo(() => {
    return {
      getScrollLoadObserver
    }
  }, [getScrollLoadObserver])
}
