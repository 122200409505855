import React from 'react'

// Styles import
import './sectionDivider.scss'

// Export component
const SectionDivider = () => {
  return (
    <div className="ervn-section-divider"></div>
  )
}

export default SectionDivider
