import React from 'react'

// Export component
const IntroContent = () => {
  return (
    <p>I'm a full stack developer creating and realizing for the web and mobile&nbsp;<span className="ervn-oscillate">...</span></p>
  )
}

export default IntroContent
