import React from 'react'

// Export component
const BookGalleryContent = () => {
  return (
    <>
      <h3 style={{ marginTop: '60px' }}>Book Gallery West</h3>
      <p style={{ margin: 0 }}><a href="https://www.bookgallerywest.com/" target="_blank" rel="noreferrer">Book Gallery West</a> is an independent book store that I had the honor and privilege to manage for many years.</p>
    </>
  )
}

export default BookGalleryContent
