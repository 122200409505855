import React from 'react'

// Image imports
import secretSquirrelLogo from '../../../assets/secretSquirrelLogo.svg'

// Material component imports
import { Avatar, IconButton } from '@mui/material'

// Export component
const SecretSquirrelContent = () => {
  return (
    <div id="ErvnSecretSquirrelContent">
      <div className="ervn-icon-header">
        <IconButton href="https://secretsquirrelrehab.org" target="_blank" rel="noreferrer">
          <Avatar className="header-logo" src={secretSquirrelLogo} />
        </IconButton>
        <h3>Secret Squirrel Development</h3>
      </div>
      <div>
        <p><a href="https://secretsquirrelrehab.org" target="_blank" rel="noreferrer">Secret Squirrel Wildlife Rehabilitation</a> is a non-profit dedicated to rehabilitating wildlife and educating about wildlife rehabilitation.</p>
        <p>I'm working on a custom (React, Node, MongoDb) content management system that will be an education and information portal that includes custom tools for rehabbers. We're still a little ways out, but I'm excited for it's upcoming launch.</p>
      </div>
    </div>
  )
}

export default SecretSquirrelContent
