import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

// Style imports
import './fadeSection.scss'

// root
import { useHelperFunctions } from '../../hooks/useObserver'

// Export component
const FadeSection = ({
  fadeInOnLoad,
  fadeInOnLoadDelay,
  reverseThreshold,
  rootMargin,
  rootRef,
  showOnLoad,
  threshold,
  watchElementRef,
  children
}) => {
  const { getScrollLoadObserver } = useHelperFunctions()
  const ref = useRef()

  useEffect(() => {
    if (fadeInOnLoad) {
      setTimeout(() => {
        ref.current.className = "ervn-scroll-load ervn-show"
      }, fadeInOnLoadDelay)

      return
    }

    if (ref.current) {
      const observer = getScrollLoadObserver({
        watchElementRef: watchElementRef || ref,
        targetElementRef: ref,
        rootMargin,
        rootRef,
        threshold,
        reverseThreshold
      })

      observer.observe(watchElementRef.current || ref.current)
    }
  }, [fadeInOnLoad, fadeInOnLoadDelay, getScrollLoadObserver, ref, reverseThreshold, rootMargin, rootRef, threshold, watchElementRef])

  const className = `ervn-scroll-load${showOnLoad ? ' ervn-show' : ''}`

  return <div className={className} ref={ref}>{children}</div>
}

// Export component props
FadeSection.propTypes = {
  fadeInOnLoad: PropTypes.bool,
  fadeInOnLoadDelay: PropTypes.number,
  children: PropTypes.any,
  reverseThreshold: PropTypes.bool,
  rootMargin: PropTypes.string,
  rootRef: PropTypes.any,
  showOnLoad: PropTypes.bool,
  threshold: PropTypes.number,
  watchElementRef: PropTypes.any
}
FadeSection.defaultProps = {
  reverseThreshold: false,
  rootMargin: '0px',
  showOnLoad: false,
  threshold: 0
}

export default FadeSection
